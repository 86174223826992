import React, { Fragment } from "react";
import { Card, CardHeader, CardBody, CardText, CardTitle } from "reactstrap";
import Container from "../common/components/UI/Container";
import Logo from "../common/components/UIElements/Logo";
import LogoImage from "../common/assets/image/logo/bitexbot-logo-text.png";
import Seo from "components/seo";

const Disclaimer = () => {
  return (
    <Fragment>
      <Seo
        title="Disclaimer: No Investment Advice Provided"
        description="Any information contained on the Bitexbot website is provided as general market information for educational and entertainment purposes only"
      />
      <section className="w-100 text-center my-3">
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Bitexbot"
          className="main-logo"
        />
      </section>
      <section>
        <Container className="px-5">
          <Card>
            <CardHeader className="h2">Disclaimer</CardHeader>
            <CardBody>
              <CardTitle tag="h4">No Investment Advice Provided</CardTitle>
              <CardText>
                Any opinions, chats, messages, news, research, analyses, prices,
                or other information contained on this Website are provided as
                general market information for educational and entertainment
                purposes only, and do not constitute investment advice. The
                Website should not be relied upon as a substitute for extensive
                independent market research before making your actual trading
                decisions. Opinions, market data, recommendations or any other
                content is subject to change at any time without notice.
                Bitexbot will not accept liability for any loss or damage,
                including without limitation any loss of profit, which may arise
                directly or indirectly from use of or reliance on such
                information.
              </CardText>
              <CardText>
                We do not recommend the use of technical analysis as a sole
                means of trading decisions. We do not recommend making hurried
                trading decisions. You should always understand that PAST
                PERFORMANCE IS NOT NECESSARILY INDICATIVE OF FUTURE RESULTS.
              </CardText>
            </CardBody>
          </Card>
        </Container>
      </section>
    </Fragment>
  );
};
export default Disclaimer;
